<template>
  <div id="contents" class="contents product page" v-if="products">
    <div class="content_head">
      <!-- s: 페이지 타이틀 -->
      <div class="page_ttl_wrap has_btn">
        <h2 class="page_ttl">제휴상품 & 요금제</h2>
        <div class="btn_group">
          <span>최근 발행일시 {{recentPublishTime ? moment(recentPublishTime).format('YYYY.MM.DD HH:mm:ss') : '없음'}}</span>
          <!-- Dev: 변경사항이 없을시 비활성화 -->
          <button type="button" class="btn strong2 w_m h_l" @click="publish">발행하기</button>
        </div>
      </div>
    </div>
    <!-- e: 페이지 타이틀 -->
    <!-- s: content_body -->
    <div class="content_body">
      <!-- s: item_list_sec-->
      <div class="item_list_sec">
        <!-- Dev: 상품 추가 wrap -->
        <div class="item_list_wrap none min" :class="{is_active : clickedProductHash == null}"
             @click="clickAddBtn()">
          <div class="item_list_head min">
            <div class="item_thumb">
              <button>추가버튼</button>
            </div>
          </div>
        </div>
        <!-- Dev : 등록된 상품  -->
        <div class="registration_item_wrap">
          <Container class="registration_item_area"
                     orientation="horizontal"
                     tag="div"
                     :drop-placeholder="dropPlaceholderOptions"
                     @drop="onDrop">
            <Draggable class="item_list_wrap min" v-for="(item, index) in products" :key="'item' + item.hash"
                       :class="{is_active : item.hash === clickedProductHash}" @click="clickProduct(item, item.hash)">
              <div class="item_list_head min">
                <!-- Dev 210102 추가 : 아이템 별 타입이 노출되어야 합니다. -->
                <div class="item_thumb" :style="`background-image: url(${getFile(item.productImage)})`">
                </div>
                <div class="delete_btn_area">
                  <button class="delete_btn" @click="removeList(item.hash)">삭제버튼</button>
                </div>
              </div>
              <!--              <div class="item_list_body">
                              {{item.file}}
                            </div>-->
            </Draggable>
          </Container>
        </div>
      </div>
      <!-- e: item_list_sec-->


      <!-- Dev : 211230 상품 타입 추가 -->
      <!-- s : template_sec -->
      <!--      <div class="template_sec">-->
      <!--        <div class="template_wrap">-->
      <!--          <div class="template_label">-->
      <!--            <p>상품 타입 선택</p>-->
      <!--          </div>-->
      <!--          <div class="template_mode_area">-->
      <!--            <button class="btn w_sl h_l" v-for="(item, i) in typeList" :key="'type' + i"-->
      <!--                    :class="{strong : i === typeListIndex}" @click="typeListIndex = i">-->
      <!--              {{item.type}}-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- e : template_sec -->

      <!-- s: preview_sec -->
      <div class="preview_sec">
        <div class="preview_wrap">
          <!-- Dev : psd 다운로드 버튼 추가 -->
          <a class="psd_down_btn" :href="`${publicPath}file/product.zip`" download="">
            PSD 다운로드
          </a>
          <div class="upload_guide" role="button">
            <div class="tooltip_area">
              <img src="../../../../assets/img/common/tooltip.png" alt="">
              <p>파일 업로드 가이드</p>
            </div>
            <div class="layer_info">
              <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                <div class="ttl">{{item.ttl}}</div>
                <div class="cnt">
                  <p>{{item.file}}</p>
                  <p>{{item.memory}}</p>
                  <p>{{item.display}}</p>
                </div>
              </div>
              <p class="info_txt">※ 파일명은 영어로 등록해주세요.</p>
            </div>
          </div>
          <div class="preview_area">
            <!-- Dev s: 등록된 파일 없을 경우 -->
            <template v-if="!clickedProduct.productImage">
              <div class="img_area_product">
                <div class="upload_btn" role="button">
                  <div class="txt_area">
                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                  </div>
                  <div class="btn_area">
                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                    <input type="file" accept="image/png,image/jpeg"
                           @change="onFileChange($event, 'productImage')">
                  </div>
                  <div class="info_area">
                    <p>png/jpg, 600*320</p>
                  </div>
                </div>
              </div>
            </template>
            <!-- Dev e: 등록된 파일 없을 경우 -->

            <!-- Dev s: 등록된 파일 있을 경우 -->
            <template v-else>
              <div class="img_area_product exist">
                <div class="img_box"
                     :style="`background-image: url(${getFile(clickedProduct.productImage)})`">
                  <template v-if="clickedProduct.productImage">
                    <div class="hover_btn_area">
                      <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                      <button class="modify_btn">
                        <img src="../../../../assets/img/common/video_modify.png" alt="">
                        <input type="file" accept="image/png,image/jpeg"
                               @change="onFileChange($event, 'productImage')">
                      </button>
                      <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                      <button class="delete_btn" @click="deleteImage('productImage')">삭제</button>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <!-- Dev e: 등록된 파일 없을 경우 -->

            <!-- Dev s: 등록된 파일 없을 경우 -->
            <template v-if="!clickedProduct.productVideo && !clickedProduct.productDescImage">
              <div class="video_area_product" style="background-image: url('')">
                <div class="upload_btn" role="button">
                  <div class="txt_area">
                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                  </div>
                  <div class="btn_area">
                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                    <input type="file" accept="video/mp4"
                           @change="onFileChange($event, 'productVideo')">
                  </div>
                  <div class="info_area">
                    <p>mp4, 1920*1080</p>
                  </div>
                </div>
              </div>
            </template>
            <!-- Dev e: 등록된 파일 없을 경우 -->

            <!-- Dev s: 등록된 파일 있을 경우 -->
            <template v-else>
              <div class="video_area_product exist" :class="{'none_video' : !clickedProduct.productVideo}">
                <template v-if="clickedProduct.productVideo">
                  <video ref="videoRef" class="video_box"
                         style="background-color: #333" :src="getFile(clickedProduct.productVideo)">
<!--                    <source >-->
                  </video>
                  <button class="video_play_btn play" @click="playVideo('play')" v-if="!playStatus">
                    <img src="../../../../assets/img/common/video_play_btn.png" alt="">
                  </button>
                  <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                    <img src="../../../../assets/img/common/video_pause_btn.png" alt="">
                  </button>
                  <div class="hover_btn_area">
                    <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                    <button class="modify_btn">
                      <img src="../../../../assets/img/common/video_modify.png" alt="">
                      <input type="file" accept="video/mp4"
                             @change="onFileChange($event, 'productVideo')">
                    </button>
                    <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                    <button class="delete_btn" @click="deleteImage('productVideo')">삭제</button>
                  </div>
                </template>
                <template v-else>
                  <div class="upload_btn" role="button">
                    <div class="txt_area">
                      <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                    </div>
                    <div class="btn_area">
                      <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                      <input type="file" accept="video/mp4"
                             @change="onFileChange($event, 'productVideo')">
                    </div>
                    <div class="info_area">
                      <p>mp4, 1920*1080</p>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <!-- Dev e: 등록된 파일 있을 경우 -->

            <!-- Dev s: 등록된 파일 없을 경우 -->
            <template v-if="!clickedProduct.productDescImage">
              <div class="info_area_product">
                <div class="upload_btn" role="button">
                  <div class="txt_area">
                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                  </div>
                  <div class="btn_area">
                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                    <input type="file" accept="image/png"
                           @change="onFileChange($event, 'productDescImage')">
                  </div>
                  <div class="info_area">
                    <p>png, 980*880</p>
                  </div>
                </div>
              </div>
            </template>
            <!-- Dev e: 등록된 파일 없을 경우 -->

            <!-- Dev s: 등록된 파일 있을 경우 -->
            <template v-else>
              <div class="info_area_product exist">
                <div class="info_box"
                     :style="`background-image: url(${getFile(clickedProduct.productDescImage)})`">
                  <template v-if="clickedProduct.productDescImage">
                    <div class="hover_btn_area">
                      <!--  Dev: 파일 수정 버튼 클릭시 파일첨부 호출 -->
                      <button class="modify_btn">
                        <img src="../../../../assets/img/common/video_modify.png" alt="">
                        <input type="file" accept="image/png"
                               @change="onFileChange($event, 'productDescImage')">
                      </button>
                      <!--  Dev: 파일 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                      <button class="delete_btn" @click="deleteImage('productDescImage')">삭제
                      </button>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <!-- Dev e: 등록된 파일 없을 경우 -->
          </div>
        </div>
      </div>
      <!-- e: preview_sec -->
    </div>
    <!-- e: content_body -->

    <!-- s: content_foot -->
    <div class="content_foot">
      <div class="btn_group center">
        <!-- Dev : 첨부파일에 대한 부분만 리셋        -->
        <!--                <button v-if="!clickedProductHash" class="btn basic w_m h_l" @click="resetProduct">취소</button>-->
        <!-- Dev : 클릭 시 메인 영상 등록: 파일 첨부 반영  -->
        <button v-if="!clickedProductHash" class="btn strong w_m h_l" @click="createProduct">저장</button>
        <button v-else class="btn strong w_m h_l" @click="editProduct">저장</button>
      </div>
    </div>
    <!-- e : content_foot  -->
  </div>
  <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
          @yesDialog="deleteProduct()">
    <template #body>
      <h3>삭제 확인</h3>
      <p>선택한 상품을 삭제하시겠습니까?<br>
        변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다</p>
    </template>
  </Dialog>

  <!-- Dev :  페이지 나가기, 새로고침, 브라우저 닫기 시 호출 -->
  <Dialog :isOpen="pageOutDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="pageOutDialogState = false"
          @yesDialog="pageOut()">
    <template #body>
      <h3>페이지에서 나가시겠습니까?</h3>
      <p>변경된 내용은 “발행하기”를 진행해야 체험존에 반영됩니다.</p>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment";
import {Container, Draggable} from "vue3-smooth-dnd";
import Dialog from "@/components/dialog";
import Product from "@/plugins/firebase/model/product";
import {applyDrag} from "@/plugins/dnd-helpers";
import Smart from "@/plugins/firebase/model/smart";

export default {
  name: "new-index",
  layout: 'new-admin',
  components: {
    Container: Container,
    Draggable: Draggable,
    Dialog,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      moment: moment,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      imgFile: true,
      isActive: false,
      activeIndex: 0,
      playStatus: false,
      deleteDialogState: false,
      pageOutDialogState: false,
      typeListIndex: 0,
      typeList: [
        {
          type: '제휴상품',
          value: 1,
        },
        {
          type: '요금제',
          value: 2,
        },
      ],
      tooltip: [
        {
          ttl: '상품 이미지',
          file: 'PNG, JPG',
          memory: '용량 1MB 이하',
          display: '해상도 600*320',
        },
        {
          ttl: '상품 설명 이미지',
          file: 'PNG',
          memory: '용량 1MB 이하',
          display: '해상도 980*880',
        },
        {
          ttl: '상품 영상',
          file: 'MP4',
          memory: '용량 130MB 이하',
          display: '해상도 1920*1080',
        },
      ],

      submenu_product_publish_key: 'publish/submenu/product',
      submenu_product_root_key: 'submenu/product',
      recentPublishTime: '',
      products: null,
      originalProducts: {},
      clickedProduct: new Product(),
      clickedProductHash: null,
      deleteProductHash: null,
      canLeaveSite: true,
      isUploading: false,
    }
  },
  methods: {
    async publish() {
      const time = this.timeToString(moment());
      await this.$firebase.database().ref(this.submenu_product_publish_key).child('lastPublishDateTime').set(time);
      this.toastSuccess('발행 완료.');
    },
    onFileChange(e, key) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      let file = files[0];

      const isImage = this.isImage(file);
      const isVideo = this.isVideo(file);
      if (isImage) {
        const maxImageSize = 1024 * 1024;
        if (maxImageSize <= file.size) {
          this.toastDanger('이미지 용량은 1MB 이하로 등록해주세요.');
          return;
        }
      } else if (isVideo) {
        const maxVideoSize = 130 * 1024 * 1024;
        if (maxVideoSize <= file.size) {
          this.toastDanger('영상 용량은 130MB 이하로 등록해주세요.');
          return;
        }

        const video = this.$refs.videoRef;
        if (video) {
          this.playStatus = false;
          const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
          if (isVideoPlaying) {
            video.pause();
          }
          video.load();
        }
      } else {
        this.toastDanger('이미지는 PNG, JPG 파일, 영상은 MP4만 가능합니다.');
        return;
      }

      this.clickedProduct[key] = file;
      this.canLeaveSite = false;
    },
    onDrop(dropResult) {
      if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
        this.products[dropResult.removedIndex].order = dropResult.addedIndex + 1;
        this.products[dropResult.addedIndex].order = dropResult.removedIndex + 1;

        /*const removed = this.products[dropResult.removedIndex];
        const added = this.products[dropResult.addedIndex];
        this.$firebase.database().ref(this.submenu_product_root_key).child(removed.hash).set(removed);
        this.$firebase.database().ref(this.submenu_product_root_key).child(added.hash).set(added);
        this.clickedProduct = added;
        this.clickedProductHash = added.hash;*/
      }
      this.products = applyDrag(this.products, dropResult);
      this.products.forEach((item, index) => {
        item.order = index + 1;
      });
      this.products = this.products.sort((a, b) => {
        return a.order - b.order;
      });
      this.products.forEach((item) => {
        this.$firebase.database().ref(this.submenu_product_root_key).child(item.hash).set(item);
      });
      const clickProduct = this.products[dropResult.addedIndex];
      this.clickProduct(clickProduct, clickProduct.hash);
      this.canLeaveSite = false;
    },
    playVideo(status) {
      const video = document.querySelector('.video_box');
      if (status === 'play') {
        video.play();
        this.playStatus = true;
      } else {
        video.pause();
        this.playStatus = false;
      }
    },
    async deleteProduct() {
      this.deleteDialogState = false;
      // 2022-01-25 파일 삭제 임시 주석
      //await this.deleteFolderContents(this.deleteProductHash);
      this.$firebase.database().ref(this.submenu_product_root_key).child(this.deleteProductHash).remove().then(async () => {
        this.deleteProductHash = null;
        this.toastSuccess('상품이 삭제 되었습니다.');
        this.reOrdering();
        this.reloadData().then(() => {
          if (this.products && this.products.length > 0) {
            const product = this.products[0];
            this.clickProduct(product, product.hash);
          } else {
            this.clickAddBtn();
          }
        });
      });
      this.canLeaveSite = false;
    },
    removeList(hash) {
      this.deleteProductHash = hash;
      this.deleteDialogState = true;
    },
    pageOut() {
      this.pageOutDialogState = false;
    },

    clickAddBtn() {
      this.clickedProduct = new Product();
      this.clickedProductHash = null;
    },
    clickProduct(item, hash) {
      const prevHash = this.clickedProduct.hasOwnProperty('hash') ? this.clickedProduct.hash : null;
      if (prevHash && this.originalProducts.hasOwnProperty(prevHash)) {
        const original = this.originalProducts[prevHash];
        const find = this.products.findIndex((item) => item.hash === prevHash);
        if (find > -1) {
          this.products[find] = JSON.parse(JSON.stringify(original));
        }
      }
      this.clickedProduct = item;
      this.clickedProductHash = hash;
    },
    async createProduct() {
      if (this.isUploading) {
        return;
      }

      const product = this.clickedProduct;
      if (!this.isImage(product.productImage)) {
        this.toastDanger('상품 이미지를 등록해주세요.');
        return;
      }
      if (!this.isImage(product.productDescImage)) {
        this.toastDanger('상품 설명 이미지를 등록해주세요.');
        return;
      }
      /*
      if (!this.isVideo(product.productVideo)) {
        this.toastDanger('영상을 등록해주세요.');
        return;
      }
      */
      this.isUploading = true;
      this.loadingOverlay(async (loader) => {
        product.order = 1;
        for await (const item of this.products) {
          const index = this.products.indexOf(item);
          item.order = index + 2;
          await this.$firebase.database().ref(this.submenu_product_root_key).child(item.hash).set(item);
        }
        /*await this.$firebase.database().ref(this.submenu_product_root_key).once('value', async (sn) => {
            let newOrder = 1;
            let data = sn.val();
            if (data) {
                newOrder = Object.keys(sn.val()).length + 1;
                product.order = newOrder;
            } else {
                product.order = 1;
            }
        });*/
        const hash = this.$firebase.database().ref(this.submenu_product_root_key).push(product).key;
        const files = product.productVideo ? [product.productImage, product.productDescImage, product.productVideo] : [product.productImage, product.productDescImage];
        let fileLinks = [];
        product.creationDateTime = this.timeToString(moment());
        this.$Progress.start();
        for await (const file of files) {
          if (file && file instanceof File) {
            const index = files.indexOf(file);
            let downloadUrl = await this.upload(hash, file, index, files.length);
            fileLinks[index] = downloadUrl;
          }
        }
        product.hash = hash;
        product.productImage = fileLinks[0];
        product.productDescImage = fileLinks[1];
        if(fileLinks[2]) product.productVideo = fileLinks[2];
        this.$Progress.finish();
        product.lastModifiedDateTime = this.timeToString(moment());
        await this.$firebase.database().ref(this.submenu_product_root_key).child(hash).set(product).then(() => {
          this.toastSuccess('새 상품 등록되었습니다.');
          this.clickedProductHash = hash;
          this.reloadData().then((data) => {
            if (data) {
              const products = data.val();
              if (products) {
                for (const [hash, product] of Object.entries(products)) {
                  this.originalProducts[hash] = JSON.parse(JSON.stringify(product));
                }
              }
            }
            this.clickCard(product, hash);
          });
        });
        this.canLeaveSite = false;

        this.isUploading = false;
        loader.hide();
      });
    },
    async editProduct() {
      if(this.isUploading){
        return;
      }

      const product = this.clickedProduct;
      if (!this.isImage(product.productImage)) {
        this.toastDanger('상품 이미지를 등록해주세요.');
        return;
      }
      if (!this.isImage(product.productDescImage)) {
        this.toastDanger('상품 설명 이미지를 등록해주세요.');
        return;
      }
      /*
      if (!this.isVideo(product.productVideo)) {
        this.toastDanger('영상을 등록해주세요.');
        return;
      }
      */
      this.isUploading = true;
      this.loadingOverlay(async (loader) => {
        const hash = this.clickedProductHash;
        const files = product.productVideo ? [product.productImage, product.productDescImage, product.productVideo] : [product.productImage, product.productDescImage];
        let fileLinks = [];
        product.creationDateTime = this.timeToString(moment());
        this.$Progress.start();
        for await (const file of files) {
          if (file && file instanceof File) {
            const index = files.indexOf(file);
            let downloadUrl = await this.upload(hash, file, index, files.length);
            fileLinks[index] = downloadUrl;
          }
        }
        product.productImage = fileLinks[0] ? fileLinks[0] : product.productImage;
        product.productDescImage = fileLinks[1] ? fileLinks[1] : product.productDescImage;
        product.productVideo = fileLinks[2] ? fileLinks[2] : product.productVideo;
        this.$Progress.finish();
        product.lastModifiedDateTime = this.timeToString(moment());
        await this.$firebase.database().ref(this.submenu_product_root_key).child(hash).set(product).then(() => {
          this.toastSuccess('상품이 수정되었습니다.');
          this.clickedProductHash = hash;
          this.reloadData().then((data) => {
            if (data) {
              const products = data.val();
              if (products) {
                for (const [hash, product] of Object.entries(products)) {
                  this.originalProducts[hash] = JSON.parse(JSON.stringify(product));
                }
              }
            }
          });
        });
        this.canLeaveSite = false;

        this.isUploading = false;
        loader.hide();
      });
    },
    async upload(hash, image, current, total) {
      let storageRef = this.$firebase.storage().ref();
      let imageRef = storageRef.child(hash).child(image.name);
      let uploadTask = imageRef.put(image);
      return new Promise(((resolve, reject) => {
        uploadTask.on('state_changed', (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const gage = (Math.floor(100 / total) * current) + (Math.floor(progress) / total);
          this.$Progress.set(gage);
          //console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
              //console.log('Upload is paused');
              break;
            case this.$firebase.storage.TaskState.RUNNING: // or 'running'
              //console.log('Upload is running');
              break;
          }
        }, (error) => {
          console.error('[ERROR] state_changed', error);
          reject(error);
        }, () => {
          // 완료 다운로드 예시
          resolve(uploadTask.snapshot.ref.getDownloadURL());
        });
      }));
    },
    deleteImage(key) {
      this.clickedProduct[key] = '';
      this.canLeaveSite = false;
    },
    reOrdering() {
      this.$firebase.database().ref(this.submenu_product_root_key).once('value', (sn) => {
        const products = sn.val() || {};
        let temps = [];
        for (const [hash, product] of Object.entries(products)) {
          product.hash = hash;
          temps.push(product);
        }
        temps = temps.sort((a, b) => a.order - b.order);
        for (const [index, product] of temps.entries()) {
          product.order = index + 1;
          const hash = product.hash;
          // delete product.hash;
          this.$firebase.database().ref(this.submenu_product_root_key).child(hash).set(product);
        }
      });
      this.canLeaveSite = false;
    },
    reloadData() {
      this.$firebase.database().ref(this.submenu_product_publish_key).once('value', (sn) => {
        const publish = sn.val() || {};
        if (publish) {
          this.recentPublishTime = publish.lastPublishDateTime;
        }
      });
      return this.$firebase.database().ref(this.submenu_product_root_key).once('value', (sn) => {
        const products = sn.val() || {};
        const productArr = [];
        for (const [hash, product] of Object.entries(products)) {
          product.hash = hash;
          productArr.push(product);
        }
        this.products = productArr.sort((a, b) => a.order - b.order);
      });
    },
    deleteFolderContents(path) {
      const ref = this.$firebase.storage().ref(path);
      return ref.listAll()
          .then(dir => {
            dir.items.forEach(fileRef => {
              this.deleteFile(ref.fullPath, fileRef.name);
            });
            dir.prefixes.forEach(folderRef => {
              this.deleteFolderContents(folderRef.fullPath);
            })
          })
          .catch(error => {
            console.log(error);
          });
    },
    deleteFile(pathToFile, fileName) {
      const ref = this.$firebase.storage().ref(pathToFile);
      const childRef = ref.child(fileName);
      childRef.delete()
    },
    resetProduct() {
      this.clickedProduct.productImage = '';
      this.clickedProduct.productDescImage = '';
      this.clickedProduct.productVideo = '';
    },
    changType() {
      console.log(1)
      if (this.typeListIndex === 1) this.typeListIndex = 0
      else this.typeListIndex = 1
    },
  },
  created() {
    this.$firebase.database().ref(this.submenu_product_publish_key).on('value', (sn) => {
      const publish = sn.val() || {};
      if (publish) {
        this.recentPublishTime = publish.lastPublishDateTime;
      }
    });
    this.reloadData().then((data) => {
      if (data) {
        const products = data.val();
        if (products) {
          for (const [hash, product] of Object.entries(products)) {
            this.originalProducts[hash] = JSON.parse(JSON.stringify(product));
          }
        }
      }
      if (this.products && this.products.length > 0) {
        const product = this.products[0];
        this.clickProduct(product, product.hash);
      } else {
        this.clickAddBtn();
      }
    });
  },
  mounted() {
    window.addEventListener('beforeunload', this.unLoadEvent);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.unLoadEvent);
  },
  beforeRouteLeave(to, from, next) {
    if (this.canLeaveSite) next();
    else if (confirm('이 사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.')) {
      next();
    }
  }
}
</script>

<style scoped>

</style>
